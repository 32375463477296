import React from 'react'
import Layout from '../../components/Layout'

const Thanks = class extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        let el = document.getElementById('footer');
        el.style.position = 'fixed';
        setTimeout(() => {
            window.location.href = "/"
        }, 7000)
    }

    componentWillUnmount() {
        let el = document.getElementById('footer');
        el.style.position = 'relative';
    }

    render() {
       return (

                <Layout>
                    <section className="section">
                        <div className="container">
                            <div className="content">
                                <h1>Thank you!</h1>
                                <p>Your message has been received. A member of our team will respond to your message as
                                    soon as possible.</p>
                            </div>
                        </div>
                    </section>
                </Layout>

       )

    }
}

export default Thanks
